<div class="bo-simple-file-upload">
  <p-fileUpload
    [auto]="true"
    [customUpload]="true"
    [accept]="accept"
    [multiple]="true"
    [maxFileSize]="maxFileSize"
    [invalidFileSizeMessageDetail]="errorTypes.InvalidFileSize"
    [invalidFileTypeMessageDetail]="errorTypes.InvalidFileType"
    (onSelect)="onPreselect()"
    (uploadHandler)="onSelectFn($event)"
    #fileUpload
  >
    <ng-template pTemplate="content">
      <div class="bo-simple-file-upload__container bo-simple-file-upload__button">
        <app-button
          [text]="'MTR_COMMON_CHOOSE_MULTIPLE' | translate"
          [disabled]="disabled"
          (onClick)="fileUpload.choose()"
          extraClasses="bo-button--secondary"
        />
      </div>
      @if (parsedErrorMessages.length) {
        <div class="bo-simple-file-upload__container">
          <ul>
            @for (msg of parsedErrorMessages || []; track msg.fileName) {
              <li class="bo-simple-file-upload__error">
                {{
                  msg.messageKey | translate: { name: msg.fileName, size: msg.size, type: msg.type }
                }}
              </li>
            }
          </ul>
        </div>
      }
      <div class="bo-simple-file-upload__container">
        <h3 class="bo-simple-file-upload__subtitle">
          {{ 'MTR_COMMON_SIMPLE_FILE_UPLOAD_SELECTED_FILES_TITLE' | translate }}
        </h3>
        <ul *ngIf="uploadedFiles.length; else noFilesSelected">
          <li class="bo-simple-file-upload__file" *ngFor="let file of uploadedFiles">
            {{ file.name }}
          </li>
        </ul>
        <ng-template #noFilesSelected>
          <ul>
            <li>
              {{ 'MTR_COMMON_SIMPLE_FILE_UPLOAD_NO_FILES_SELECTED' | translate }}
            </li>
          </ul>
        </ng-template>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
