import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { bytesToSize } from '@core/utils/file-management.utils'
import { FileUpload } from 'primeng/fileupload'
import { SimpleFileUploadErrorTypes } from './enums/simple-file-upload.enums'
import { ERROR_MESSAGE_MAP } from './constants/simple-file-upload.constants'

@Component({
  selector: 'app-simple-file-upload',
  templateUrl: './simple-file-upload.component.html',
  styleUrl: './simple-file-upload.component.scss',
})
export class SimpleFileUploadComponent {
  @ViewChild('fileUpload', { static: true }) public fileUpload: FileUpload

  errorTypes = SimpleFileUploadErrorTypes
  uploadedFiles: any[] = []
  parsedErrorMessages: {
    messageKey: string
    fileName: string
    [key: string]: any
  }[] = []

  @Input() accept = null
  @Input() acceptTypes = []
  @Input() maxFileSize = 1000 * 1024
  @Input() disabled = false

  @Output() onFileChange = new EventEmitter()

  onPreselect() {
    this.parsedErrorMessages = this.fileUpload.msgs.map(({ summary, detail }) => ({
      messageKey: ERROR_MESSAGE_MAP[detail],
      fileName: summary.replace(/:[^:]+$/g, ''),
      size: bytesToSize(this.maxFileSize),
      type: this.acceptTypes.join(','),
    }))
  }

  onSelectFn(e) {
    this.uploadedFiles = [...e.files]
    this.onFileChange.emit(this.uploadedFiles)
  }

  clear() {
    this.fileUpload.clear()
    this.fileUpload.msgs = []
    this.uploadedFiles = []
    this.parsedErrorMessages = []
  }
}
