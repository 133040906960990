import { Injectable } from '@angular/core'
import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'

const IGNORE_HEADER_OVERRIDE = new HttpContextToken<boolean>(() => false)
export const ignoreHeaderOverride = () => new HttpContext().set(IGNORE_HEADER_OVERRIDE, true)

@Injectable()
export class JsonContentTypeInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(IGNORE_HEADER_OVERRIDE)) {
      return next.handle(request)
    }

    return next.handle(
      request.clone({
        headers: request.headers.append('Content-Type', 'application/json-patch+json'),
      }),
    )
  }
}
